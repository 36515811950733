import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import ColorModeIconDropdown from '../../shared-theme/ColorModeIconDropdown';

const cardData = [
	{
		img: 'https://h5ai.gamingfridays.org/Logos/Jellyfin.svg',
		tag: 'Media',
		title: 'Jellyfin',
		description: "Streaming media server",
		authors: [
			{ name: 'TangoLima', avatar: 'https://h5ai.gamingfridays.org/Logos/Penguin.png' }
		],
		date: 'March 28, 2025',
		url: "https://jellyfin.gamingfridays.org"
	},

	{
		img: 'https://h5ai.gamingfridays.org/Logos/Jellyseerr.svg',
		tag: 'Media',
		title: 'Jellyseerr',
		description: "Jellyfin Requester",
		authors: [
			{ name: 'TangoLima', avatar: 'https://h5ai.gamingfridays.org/Logos/Penguin.png' }
		],
		date: 'April 2, 2025',
		url: "https://jellyseerr.gamingfridays.org"
	},

	{
		img: 'https://h5ai.gamingfridays.org/Logos/Komga.svg',
		tag: 'Media',
		title: 'Komga',
		description: "Comic book and manga media server ",
		authors: [
			{ name: 'TangoLima', avatar: 'https://h5ai.gamingfridays.org/Logos/Penguin.png' }
		],
		date: 'March 28, 2025',
		url: "https://komga.gamingfridays.org"
	},

	{
		img: 'https://h5ai.gamingfridays.org/Logos/Pingvin.svg',
		tag: 'Media',
		title: 'Pingvin',
		description: "File sharing platform",
		authors: [
			{ name: 'TangoLima', avatar: 'https://h5ai.gamingfridays.org/Logos/Penguin.png' }
		],
		date: 'March 28, 2025',
		url: "https://pingvin.gamingfridays.org"
	},

	{
		img: 'https://h5ai.gamingfridays.org/Logos/Folder.svg',
		tag: 'Media',
		title: 'h5ai',
		description:
			'HTTP file indexer',
		authors: [
			{ name: 'TangoLima', avatar: 'https://h5ai.gamingfridays.org/Logos/Penguin.png' }
		],
		date: 'March 28, 2025',
		url: "https://h5ai.gamingfridays.org"
	},

	{
		img: 'https://h5ai.gamingfridays.org/Logos/Immich.svg',
		tag: 'Media',
		title: 'Immich',
		description: "Photo and video management platform",
		authors: [
			{ name: 'TangoLima', avatar: 'https://h5ai.gamingfridays.org/Logos/Penguin.png' }
		],
		date: 'April 1, 2025',
		url: "https://immich.gamingfridays.org"
	},

	{
		img: 'https://h5ai.gamingfridays.org/Logos/Crafty.png',
		tag: 'Management',
		title: 'Crafty',
		description: "Minecraft server control platform",
		authors: [
			{ name: 'TangoLima', avatar: 'https://h5ai.gamingfridays.org/Logos/Penguin.png' }
		],
		date: 'March 28, 2025',
		url: "https://crafty.gamingfridays.org"
	},

	{
		img: 'https://h5ai.gamingfridays.org/Logos/Gitea.svg',
		tag: 'Engineering',
		title: 'Gitea',
		description: "Version control platform",
		authors: [
			{ name: 'TangoLima', avatar: 'https://h5ai.gamingfridays.org/Logos/Penguin.png' }
		],
		date: 'March 28, 2025',
		url: "https://gitea.gamingfridays.org"
	},

	{
		img: 'https://h5ai.gamingfridays.org/Logos/ntfy.svg',
		tag: 'Engineering',
		title: 'ntfy',
		description: "Push notification service",
		authors: [
			{ name: 'TangoLima', avatar: 'https://h5ai.gamingfridays.org/Logos/Penguin.png' }
		],
		date: 'April 1, 2025',
		url: "https://ntfy.gamingfridays.org"
	},

	/*
	{
		img: 'https://h5ai.gamingfridays.org/Logos/qBittorrent.svg',
		tag: 'Media',
		title: 'qBittorent',
		description: "BitTorrent client",
		authors: [
			{ name: 'TangoLima', avatar: 'https://h5ai.gamingfridays.org/Logos/Penguin.png' }
		],
		date: 'April 1, 2025',
		url: "https://qbt.gamingfridays.org"
	},
	*/
];

const SyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  height: '100%',
  backgroundColor: (theme.vars || theme).palette.background.paper,
  '&:hover': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  '&:focus-visible': {
    outline: '3px solid',
    outlineColor: 'hsla(210, 98%, 48%, 0.5)',
    outlineOffset: '2px',
  },
}));

const SyledCardContent = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: 16,
  flexGrow: 1,
  '&:last-child': {
    paddingBottom: 16,
  },
});

const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

function Author({ authors, date }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}
      >
        <AvatarGroup max={3}>
          {authors.map((author, index) => (
            <Avatar
              key={index}
              alt={author.name}
              src={author.avatar}
              sx={{ width: 24, height: 24 }}
            />
          ))}
        </AvatarGroup>
        <Typography variant="caption">
          {authors.map((author) => author.name).join(', ')}
        </Typography>
      </Box>
      <Typography variant="caption">{date}</Typography>
    </Box>
  );
}

Author.propTypes = {
  authors: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default function MainContent() {
  const [focusedCardIndex, setFocusedCardIndex] = React.useState(null);

  const handleFocus = (index) => {
    setFocusedCardIndex(index);
  };

  const handleBlur = () => {
    setFocusedCardIndex(null);
  };

  return (
	<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2}}>

		<Box
			// Default for desktop
			sx={{
				display: { display: 'flex', md: 'flex', flexDirection: 'row-reverse' },
				
			}}>
			<ColorModeIconDropdown />
		</Box>
			
		<div>
		<Typography align='center' variant='h1' gutterBottom> Gaming Fridays</Typography>
		<Typography align='center' variant='h3'>External Site Map</Typography>
		</div>

		<Box
		sx={{
			display: { xs: 'flex', sm: 'none' },
			flexDirection: 'row',
			gap: 1,
			width: { xs: '100%', md: 'fit-content' },
			overflow: 'auto',
		}}
		>
		</Box>
		<Box
		sx={{
			display: 'flex',
			flexDirection: { xs: 'column-reverse', md: 'row' },
			width: '100%',
			justifyContent: 'space-between',
			alignItems: { xs: 'start', md: 'center' },
			gap: 4,
			overflow: 'auto',
		}}
		>
		<Box
			sx={{
			display: 'inline-flex',
			flexDirection: 'row',
			gap: 3,
			overflow: 'auto',
			}}
		>
		</Box>
		<Box
			sx={{
			display: { xs: 'none', sm: 'flex' },
			flexDirection: 'row',
			gap: 1,
			width: { xs: '100%', md: 'fit-content' },
			overflow: 'auto',
			}}
		>
		</Box>
		</Box>

		<Grid container spacing={2} columns={12}>
			{(() => {
			const options = [];
			for(let i = 0; i < cardData.length; i++)
			{
				options.push(
					<Grid size={{ xs: 12, md: 4 }}>
					<SyledCard
						variant="outlined"
						onClick={() => window.location=cardData[i].url}
						onFocus={() => handleFocus(i)}
						onBlur={handleBlur}
						tabIndex={i}
						className={focusedCardIndex === i ? 'Mui-focused' : ''}
					>
						<CardMedia
						component="img"
						image={cardData[i].img}
						sx={{
							aspectRatio: '4 / 4',
							borderBottom: '1px solid',
							borderColor: 'divider',
						}}
						/>
						<SyledCardContent>
						<Typography gutterBottom variant="caption" component="div">
							{cardData[i].tag}
						</Typography>
						<Typography gutterBottom variant="h6" component="div">
							{cardData[i].title}
						</Typography>
						<StyledTypography variant="body2" color="text.secondary" gutterBottom>
							{cardData[i].description}
						</StyledTypography>
						</SyledCardContent>
						<Author authors={cardData[i].authors} />
					</SyledCard>
					</Grid>
				);
				}
				return options;
			})()}
		</Grid>

    </Box>
  );
}
